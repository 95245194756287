import React from 'react';

import Register from '../registerPage/Register.react';
import ConfirmationSuccessComponent from './ConfirmationSuccessComponent.react';

import { BaseProps } from '../../auth.types.react';

const ConfirmationSuccess = ({ store, router, route }: BaseProps) => {
  return (
    <Register
      route={route}
      router={router}
      store={store}
      AuthComponent={ConfirmationSuccessComponent}
    />
  );
};

export default ConfirmationSuccess;
