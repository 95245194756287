import React from 'react';

import { Card } from '@fairwindsops/ui-components';

import { strings } from '~utils/strings';
import { Title } from '~utils/texts.react';

import '../style.scss';

const RecoverEmail = () => {
  return (
    <Card className="recover">
      <Card.Body padded>
        <h1 className={Title({ size: 'sm', bottomMargin: strings.textStyling.medBottom })}>
          {strings.auth.resetPassword}
        </h1>
        <p className="recover__email-sent-sub-title">
          Please check your email for further instructions on resetting your password.
        </p>
      </Card.Body>
    </Card>
  );
};

export default RecoverEmail;
