import toast from 'react-hot-toast';

import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

export const resendEmail = async () => {
  try {
    await sendRequest('POST', '/v0/resend-confirmation', {}, null);
    toast.success('Email sent!');
  } catch (e) {
    logger.logError('error_resend_confirmation', e);
    toast.error('Error trying to resend confirmation email');
  }
};
