import React from 'react';

import CheckConfirmationComponent from './CheckConfirmationComponent.react';
import Register from '../registerPage/Register.react';

import { BaseProps } from '../../auth.types.react';

const CheckConfirmation = ({ router, route, store }: BaseProps) => {
  return (
    <Register
      route={route}
      router={router}
      store={store}
      AuthComponent={CheckConfirmationComponent}
    />
  );
};

export default CheckConfirmation;
