import React from 'react';
import { Toaster } from 'react-hot-toast';

import { resendEmail } from '../register.helpers.react';

import './style.scss';

const CheckConfirmationComponent = () => {
  return (
    <div className="check-confirmation">
      <h1 className="check-confirmation__title">Check your Email</h1>
      <p>
        Confirm the email you provided by going to your inbox and clicking the link we sent you.{' '}
        <span onClick={resendEmail} className="check-confirmation__link">
          Click here to resend
        </span>
        .
      </p>
      <Toaster />
    </div>
  );
};

export default CheckConfirmationComponent;
