import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { UPDATE_MEMBERSHIPS } from '~store/action.types';
import { ORGS, AUTH_NEW_ORG } from '~reactComponents/NavigationReact/Navigation.config.react';

import { resendEmail } from '../register.helpers.react';

import { PartialBaseProps } from '../../auth.types.react';
import { CurrentUser } from '~utils/global.types.react';

import logger from '~utils/logger';
import { getCurrentUser } from '~utils/request';
import { strings } from '~utils/strings';

import './style.scss';

const ConfirmationSuccessComponent = ({ store, router }: PartialBaseProps) => {
  const [hasOrgs, setHasOrgs] = useState<number>(0);
  const [user, setUser] = useState<CurrentUser | null>(null);

  useEffect(() => {
    // We force fetch the current user if they are not confirmed otherwise the user is fetched
    // from the router which causes a delay and "Confirmed" to be false
    if (!store()?.getters?.user?.Confirmed) {
      const getUser = async () => {
        let login;

        try {
          login = await getCurrentUser();

          if (login) {
            await store().dispatch(UPDATE_MEMBERSHIPS, login);
            setHasOrgs(store().getters.memberships.length);
            setUser(login?.user || null);
          }
        } catch (e) {
          logger.logError('error_fetching_current_user', e);
        }
      };

      getUser();
    }
  }, []);

  const goToSite = () => {
    router().push({
      name: hasOrgs ? ORGS : AUTH_NEW_ORG,
    });
  };

  return (
    <div className="confirmation-success">
      {user ? (
        <>
          <h1 className="confirmation-success__title">You're in!</h1>
          <p>Welcome to Insights! Click 'Next' to get started</p>
          <Button onClick={goToSite} variant="primary" title={strings.general.Next}>
            {strings.general.Next}
          </Button>
        </>
      ) : (
        <>
          <h1 className="confirmation-success__title">Looks like there was a problem</h1>
          <p>
            Make sure you have confirmed your email or resend the code to try again.{' '}
            <span onClick={resendEmail} className="confirmation-success__link">
              Click here to resend
            </span>
          </p>
        </>
      )}
    </div>
  );
};

export default ConfirmationSuccessComponent;
